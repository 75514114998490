<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        a) If the
        <stemble-latex content="$\text{pK}_\text{a}$" />
        of
        <chemical-latex content="HNO2" />
        is 3.35 and the pH of an
        <chemical-latex content="HNO2/NaNO2" />
        solution is 3.25, which of the following is
        <b>TRUE</b>?
      </p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question538',
  components: {
    ChemicalLatex,
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: '$\\ce{HNO2 < [NaNO2]}$', value: 'HAlessThanA'},
        {text: '$\\ce{HNO2 = [NaNO2]}$', value: 'HAequalToA'},
        {text: '$\\ce{HNO2 > [NaNO2]}$', value: 'HAgreaterThanA'},
        {text: '$\\ce{HNO2 = 10\\cdot[NaNO2]}$', value: 'HAequalTo10TimesA'},
      ],
    };
  },
};
</script>
